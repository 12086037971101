// Copyright (C) Microsoft Corporation. All rights reserved.

import {
    from,
    fromEvent,
    mergeMap,
    Subject,
    Subscription,
} from 'rxjs';

// import dependencyManager from '../_services/dependency-manager';
import { IEventManager, IMessageEvent } from '../_services/event-manager';

import { IVideoPlayback } from '../_types/interfaces/ads';
import {
    AdEventNames,
    AdPlacementType,
    EventTypes,
    TelemetryEventNames,
} from '../_types/enums';

import { telemetry, Utilities } from '../_utils';

export default class PlaceholderVideo implements IVideoPlayback {
    private eventHubSubscription: Subscription;

    private telemetryProperties = {
        adPlacementType: null,
        adSessionID: null, // Unique session identifier for play/ad session
        prevEvent: null,
        placementId: null,
        vendor: 'mcg_placeholder',
        videoDuration: null,
        videoUrl: __ENV__.vendors.placeholderVideo.videoUrl,
        videoEvent: null,
    };

    private videoElement: HTMLVideoElement;

    private videoElementSubscription;

    private videoElementPlayStarted = false;

    private videoElementPauseTimeout: NodeJS.Timeout;

    private videoElementRetryPlayTimeout: NodeJS.Timeout;

    public eventHub: Subject<IMessageEvent> = new Subject();

    public set enableTestAd(testFlag: boolean) {
        // stub
    }

    public set locale(locale: string) {
        // stub
    }

    public set mcgId(mcgId: string) {
        // stub
    }

    public showPrerollClickToPlay = true;

    public vendorID = 'mcg_placeholder';

    constructor() { }

    /** PUBLIC METHODS -- AD SETUP AND TEARDOWN */

    public playAd(preroll: boolean): Promise<boolean> {
        this.telemetryProperties.adPlacementType = preroll ? AdPlacementType.preroll : AdPlacementType.interstitial;
        const adContainer = this.createAdContainer();
        if (!(adContainer instanceof HTMLElement)) {
            return Promise.reject(new Error('Couldn\'t create ad container #adDiv'));
        }

        return this.requestAds()
            .then(() => true) // Success
            .catch((err: Error) => {
                const properties = {
                    ...this.telemetryProperties,
                    info: err.message,
                };
                telemetry.trackError({ name: TelemetryEventNames.VA_NVA }, properties);
                this.eventHub.next({ name: AdEventNames.adPlaybackError, data: {}, type: EventTypes.adEvent });
                return false; // Error playing the ad
            });
    }

    public cleanup(): void {
        telemetry.flushEvent();

        this.eventHubSubscription.unsubscribe();
        this.eventHubSubscription = null;
        this.videoElementSubscription.unsubscribe();
        this.videoElementSubscription = null;
        clearTimeout(this.videoElementPauseTimeout);
        this.videoElementPauseTimeout = null;
        clearTimeout(this.videoElementRetryPlayTimeout);
        this.videoElementRetryPlayTimeout = null;

        Object.keys(this.telemetryProperties).filter((key) => key !== 'vendor').forEach((key) => {
            this.telemetryProperties[key] = null;
        });

        const nodes = [
            document.querySelector('#adDiv'),
            document.querySelector('#displayBase'),
            document.querySelector('#videoPlayer'),
        ];

        // Remove all non-zone image/iframe/script nodes that have been written into the page
        // @xxx - fix this, current version removes masque games from the page during interstitial ads
        // document.querySelectorAll('iframe, img, script').forEach((element: HTMLIFrameElement|HTMLImageElement|HTMLScriptElement) => {
        //     if (element.src) {
        //         const { host } = new URL(element.src);
        //         if (host !== window.location.host && !host.endsWith('zone.msn.com')) {
        //             nodes.push(element);
        //         }
        //     } else {
        //         nodes.push(element);
        //     }
        // });

        Utilities.removeNode(
            nodes.filter((node) => node != null),
        );
    }

    /** PRIVATE METHODS -- AD CONFIGURATION */

    private createAdContainer(): HTMLDivElement {
        const adContainer = document.createElement('div');
        adContainer.setAttribute('id', 'adDiv');
        adContainer.setAttribute('class', 'ad-player-container');

        const displayBase = document.createElement('div');
        displayBase.setAttribute('id', 'displayBase');
        displayBase.setAttribute('style', 'width:100%; height:100%;');
        displayBase.setAttribute('data-setup', '{}');

        this.videoElement = document.createElement('video');
        this.videoElement.setAttribute('id', 'videoPlayer');
        this.videoElement.setAttribute('style', 'background:#000000;width:100%;height:100%;');
        this.videoElement.setAttribute('autoplay', 'true');
        this.videoElement.setAttribute('muted', 'true');
        this.videoElement.setAttribute('playsinline', '');
        this.videoElement.setAttribute('src', __ENV__.vendors.placeholderVideo.videoUrl);

        Utilities.appendNode(displayBase, this.videoElement);
        Utilities.appendNode(adContainer, displayBase);

        Utilities.appendNode(document.querySelector('#adContainer'), adContainer);
        return adContainer;
    }

    private requestAds(): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                this.videoElementSubscription = from(['canplaythrough', 'ended', 'error', 'pause', 'play'])
                    .pipe(
                        mergeMap((event) => fromEvent(this.videoElement, event)),
                    )
                    .subscribe((event) => {
                        this.handleAdEvent(event);
                    });

                this.telemetryProperties.adSessionID = crypto.randomUUID();

                this.eventHubSubscription = this.eventHub.subscribe((event: IMessageEvent) => {
                    switch (event.name) {
                        case AdEventNames.adComplete:
                            resolve();
                            break;
                        case AdEventNames.adPlaybackError:
                            reject();
                            break;
                        default:
                            break;
                    }
                });
            } catch (err) {
                reject(new Error(err));
            }
        });
    }

    /** EVENT HANDLING */

    private handleAdEvent(event: Event): void {
        const properties = this.telemetryProperties;
        properties.videoEvent = event.type;
        switch (event.type) {
            case 'error': {
                const errorProperties = {
                    ...properties,
                    // errorCode: adError?.code,
                    // errorInfo: adError?.exception,
                    // errorMessage: adError?.errMessage,
                };
                telemetry.trackError({ name: TelemetryEventNames.VA_E }, errorProperties);
                this.eventHub.next({ name: AdEventNames.adPlaybackError, data: {}, type: EventTypes.adEvent });

                break;
            }
            case 'canplaythrough':
                telemetry.trackEvent({ name: TelemetryEventNames.VA_AI }, properties);
                if (this.videoElement.paused || !this.videoElementPlayStarted) {
                    const videoElementPlay = this.videoElement.play();
                    if (videoElementPlay !== undefined) {
                        videoElementPlay.catch((error) => {
                            const errorMessage = new Error(error).message;
                            telemetry.trackError(
                                { name: TelemetryEventNames.VA_E },
                                {
                                    ...properties,
                                    errorMessage,
                                },
                            );
                        });
                    }
                }
                break;

            case 'play':
                telemetry.trackEvent({ name: TelemetryEventNames.VAI_FiQS }, properties);
                this.videoElementPlayStarted = true;
                this.eventHub.next({
                    name: AdEventNames.adImpressionStart,
                    data: { duration: 0 }, // leave this hard-coded so we don't trigger quartile split timeout
                    type: EventTypes.adEvent,
                });
                break;

            case 'pause':
                telemetry.trackEvent({ name: TelemetryEventNames.VAI_PA }, properties);
                this.videoElementRetryPlayTimeout = setTimeout(() => {
                    const videoElementPlay = this.videoElement.play();
                    if (videoElementPlay !== undefined) {
                        videoElementPlay
                            .then(() => {
                                clearTimeout(this.videoElementPauseTimeout);
                                this.videoElementPauseTimeout = null;
                            })
                            .catch((error) => {
                                const errorMessage = new Error(error).message;
                                telemetry.trackError(
                                    { name: TelemetryEventNames.VA_E },
                                    {
                                        ...properties,
                                        errorMessage,
                                    },
                                );
                            });
                    }
                }, 1000);
                this.videoElementPauseTimeout = setTimeout(() => {
                    telemetry.trackError(
                        { name: TelemetryEventNames.VA_E },
                        {
                            ...properties,
                            errorMessage: 'pause timeout',
                        },
                    );
                    this.eventHub.next({ name: AdEventNames.adPlaybackError, data: {}, type: EventTypes.adEvent });
                }, 3000);
                break;

            case 'ended':
                telemetry.trackEvent({ name: TelemetryEventNames.VAI_FoQC }, properties);
                this.eventHub.next({ name: AdEventNames.adComplete, data: {}, type: EventTypes.adEvent });
                break;

            default:
                telemetry.trackEvent({ name: TelemetryEventNames.VAI_O }, properties);
                break;
        }

        this.telemetryProperties.prevEvent = event.type;
    }
}
